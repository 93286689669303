import { Provider } from 'react-redux';
import { App } from 'app';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import store from 'store/configureStore';
import reportWebVitals from 'reportWebVitals';
import './index.css';

import { createRoot } from 'react-dom/client';
const MOUNT_NODE = document.getElementById('root') as HTMLElement;
const root = createRoot(MOUNT_NODE);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
      </Routes>
    </BrowserRouter>
  </Provider>,
);

reportWebVitals();
